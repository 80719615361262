import axios from 'axios'
import Store from '@/store'
import { transactionConverter, transactionConverterV2 } from '@/Methods/transactionConverter'
import { CURRENCY_SHORT_CODES } from "@/constants/auto-exchange";
import Decimal from "decimal.js";
import moment from 'moment-mini'
import StatementListItemModel from '@/models/statement/StatementListItemModel'
import { statusIsActiveCb } from '@/utils/wallets'
import { notify } from '@kyvg/vue3-notification'
// import Store from '../store'

const PAGE_SIZE = 1000

const state = () => ({
  balanceObject: {},
  userTransactions: [],
  statementObject: {},
  statementInfoFilters: new Set(),
})

const mutations = {
  setStatementInfoFilters (state, payload) {
    state.statementInfoFilters = payload
  },
  setWalletObject (state, payload) {
    state.balanceObject = payload
  },
  updateWalletObjectItem (state, { walletId, accountId, currency, newValue }) {
    if (!state.balanceObject[walletId]) {
      state.balanceObject[walletId] = {
        [accountId]: {
          [currency]: newValue
        }
      }
    } else if (!state.balanceObject[walletId][accountId]) {
      state.balanceObject[walletId][accountId] = {
        [currency]: newValue
      }
    } else state.balanceObject[walletId][accountId][currency] = newValue
  },
  setUserTransactions (state, transactionList) {
    state.userTransactions = transactionList
  },
  setStatementObject (state, payload) {
    state.statementObject = payload
  },
  updateStatementObject (state, transaction) {
    const newTransaction = new StatementListItemModel(transaction)
    console.log({ newTransaction })
    if (state.statementObject[newTransaction.date]) {
      // state.statementObject[newTransaction.date].unshift(newTransaction)
      state.statementObject[newTransaction.date] = [newTransaction]
    } else {
      state.statementObject[newTransaction.date] = [newTransaction]
    }
  },
  updateUserTransactions (state, transaction) {
    state.userTransactions.push(transaction)
  }
}

const actions = {
  getTransactions ({ commit, getters, dispatch }) {
    return getAllTransactionPages(null, [])
      .then(transactions => {
        const onlyUserTransactions = transactions.filter(i => {
          return getters.userWalletsIds.includes(i.user_account_info.wallet_id)
        })
        const {
          walletsObject,
          statementObject,
          statementInfoFilters
        } = transactionConverterV2(onlyUserTransactions)
        commit('setUserTransactions', onlyUserTransactions)
        commit('setWalletObject', walletsObject)
        commit('setStatementObject', statementObject)
        commit('setStatementInfoFilters', statementInfoFilters)
        const converted = transactionConverter(transactions)
        commit('SetPayments', converted.dateMass)
        commit('SetBalance', converted.itogBalance)
        commit('SetWalletBalance', converted.balance)
        let list = []
        converted.balance.forEach(item => {
          for (let account in item.accounts) {
            item.accounts[account].amount.forEach(amount => {
              list.push(amount.currencyName)
            })
          }
        })
        commit('SetCurrencyList', list)
        commit('SetTransactionsFlags', true)
        return transactionConverter(transactions)
      })
      .catch(err => {
        console.log({ err })
        notify({
          title: window.$t('error_title'),
          text: 'Login failed',
          type: 'error'
        })
        return dispatch('signOut')
      })
  }
}

function getAllTransactionPages (bookmark, transactions) {
  return axios({
    method: 'GET',
    url: `${Store.state.backEndAddress}/transactions/page`,
    params: {
      pageSize: PAGE_SIZE,
      bookmark: bookmark
    }
  })
    .then(response => {
      if (response?.data?.status !== 200) throw Error(response?.data?.message)
      let payload = response.data.payload
      let transactionPage = payload.page_data.transactions || []
      transactions = transactions.concat(transactionPage)
      if (payload.bookmark) {
        return getAllTransactionPages(payload.bookmark, transactions)
      }
      return transactions
    })
}

const getters = {
  getMainWalletBalance: (state, getters, rootState, rootGetters) => {
    return state.balanceObject[rootGetters.mainWalletId]?.[rootGetters.mainAccountId]
  },
  getMainWalletCurrencies: (state, getters) => {
    return Object.keys(getters.getMainWalletBalance || {})
  },
  getAccountBalanceObject: state => (walletId, accountId) => {
    return state.balanceObject?.[walletId]?.[accountId] || {}
  },
  getStatementDates: state => Object.keys(state.statementObject),
  getStatementInfoFilters: state => Array.from(state.statementInfoFilters),
  getActiveWallets: (state, getters, rootState) => rootState.Wallets.userWallets.filter(statusIsActiveCb),
  getActiveAccounts: (state, getters, rootState) => walletId => {
    if (!walletId) return []
    return rootState.Wallets.userWalletAccountsMap[walletId].filter(statusIsActiveCb)
  },
  getAccountCurrencies: (state, getters) => (walletId, accountId) => {
    return Object.keys(getters.getAccountBalanceObject(walletId, accountId))
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
