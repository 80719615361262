import axios from 'axios'
import { createStep } from '@/Methods/Kyc.js'
import { sendFileInfo } from '@/Methods/SendKycFile.js'
import Store from '@/store'

const actions = {
  getKycInfo (context) {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/kyc/info`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    })
      .then(response => {
        if (response.data.status === 200) {
          return response.data.payload
        }
      })
      .then(result => {
        return result
      })
      .catch(err => {
        throw new Error(err)
      })
  },

  getKycIndividualInfo (context) {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/kyc/individual/form-data`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    })
      .then(response => {
        if (response.data.status === 200) {
          return response.data.payload
        }
      })
  },

  getFileInfo (context, data) {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/file/info/list`,
      params: {
        ids: data
      },
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    })
      .then(response => {
        if (response.data.status === 200) {
          return response.data.payload
        } else {
          throw new Error('Status 500')
        }
      })
  },

  sendFile (context, dataForSend) {
    return axios({
      method: 'POST',
      url: `${Store.state.backEndAddress}/kyc/file/upload`,
      data: dataForSend,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept-Language': 'ru-RU'
      }
    })
  },

  sendFileInfo (context, fileInfo) {
    return sendFileInfo(fileInfo)
      .then(dataForSend => {
        return axios({
          method: 'POST',
          url: `${Store.state.backEndAddress}/kyc/individual/file-info`,
          data: dataForSend,
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': 'ru-RU'
          }
        })
      })
      .catch(err => {
        throw new Error(err)
      })
  },

  sendStep (context, data) {
    return createStep(data)
      .then(response => {
        return response
      })
      .then(sendingData => {
        return axios({
          method: 'POST',
          url: `${Store.state.backEndAddress}/kyc/individual/step`,
          data: sendingData,
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': 'ru-RU'
          }
        })
          .then(response => {
            console.log(response)
            if (response.data.status === 200) {
              return true
            } else {
              throw new Error()
            }
          })
          .catch(err => {
            throw new Error(err)
          })
      })
  }
}

export default {
  actions
}
