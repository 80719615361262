import axios from 'axios'
import Store from '@/store'
import { CreateNewTransfer } from '@/Methods/CreateNewTransfer'
import { currencies } from '@/models/Currencies'

const actions = {
  transferFiatToCashier: (context, dataForSend) => {
    return axios({
      method: 'POST',
      // url: `${Store.state.backEndAddress}/admin/transfer-fiat-to-cashier`,
      url: `${Store.state.backEndAddress}/transfer`,
      data: JSON.stringify(dataForSend),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    })
  },
  createNewTransfer: (context, dataForSend) => {
    Store.commit('SetLoaderFlag', true)
    for (let item in currencies) {
      if (currencies[item] === dataForSend.RecipientCurrency) {
        dataForSend.RecipientCurrency = item
      }
    }
    return CreateNewTransfer(dataForSend)
      .then(response => {
        return response
      })
      .then(sendingData => {
        return axios({
          method: 'POST',
          url: `${Store.state.backEndAddress}/transfer`,
          data: JSON.stringify(sendingData),
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': 'ru-RU'
          }
        })
          .then(response => {
            return response
          })
      })
      .catch(err => {
        return err
      })
  }
}

export default {
  actions
}
