import axios from 'axios'
import Store from '@/store'
import { NewProgram } from '@/Methods/ActiveReferralProgram.js'
const actions = {
  getPrograms: context => {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/referral/types`
    })
  },
  getActivePrograms: context => {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/referral/list`
    })
  },
  getStatement: (context) => {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/referral/statement`
    })
  },
  ActivateProgram: (context, dataForSend) => {
    return NewProgram(dataForSend)
      .then(sendingData => {
        return axios({
          method: 'POST',
          url: `${Store.state.backEndAddress}/referral`,
          data: JSON.stringify(sendingData),
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': 'ru-RU'
          }
        })
      })
  }
}

export default {
  actions
}
