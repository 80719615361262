import axios from 'axios'
// import Store from '@/store'

const actions = {
  getGlobalLang: (context, dataLang) => {
    return axios({
      method: 'GET',
      url: `${window.location.origin}/langCryptoenter/getGlobalElements/`,
      // url: `http://localhost:3210/langCryptoenter/getGlobalElements`,
      params: {
        lang: dataLang.lang === 'zh' ? 'cn' : dataLang.lang
      },
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    })
      .then(response => {
        return response
      })
  },
  getPageLang: (context, dataLang) => {
    return axios({
      method: 'GET',
      url: `${window.location.origin}/langCryptoenter/getPage/`,
      // url: `http://localhost:3210/langCryptoenter/getPage`,
      params: {
        lang: dataLang.lang === 'zh' ? 'cn' : dataLang.lang,
        page: dataLang.page
      },
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    })
      .then(response => {
        return response
      })
  }
}

export default {
  actions
}
