import axios from 'axios'
import Store from '@/store'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const actions = {
  getPersonal: (context, id) => {
    return axios({
      method: 'GET',
      url: `${Store.state.rdbmsServiceAddress}/referral/types`
    })
  },
  setPersonalData: (context, dataForSend) => {
    return axios({
      method: 'POST',
      url: `${Store.state.rdbmsServiceAddress}/`,
      data: JSON.stringify(dataForSend),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    })
  },
  sendTgCode: (context, { code, userId }) => {
    return axios({
      method: 'POST',
      url: `${Store.state.rdbmsServiceAddress}/tg`,
      data: { code: code, anonymousUserId: userId },
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    })
  },

  sendWalletByReferralLink: (context, dataForSend) => {
    return axios({
      method: 'POST',
      url: `${Store.state.rdbmsServiceAddress}/wallets`,
      data: JSON.stringify(dataForSend),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    })
  },
  getReferral: (context, referralId) => {
    return axios({
      method: 'GET',
      url: `${Store.state.rdbmsServiceAddress}/referrals/${referralId}`
    })
  },
  getReferralByUserId: async (context, { userId }) => {
    return axios({
      method: 'GET',
      url: `${Store.state.rdbmsServiceAddress}/anonymous-users/${userId}/referral-program`
    })
      .then(response => {
        if (response.data.status === 200) {
          return response.data.payload
        } else if (response.data.status === 404) {
          return null
        }
      })
  },
  getPersonalTokenSaleEnabled: (context) => {
    return axios({
      method: 'GET',
      url: `${Store.state.rdbmsServiceAddress}/application-system-properties/PERSONAL_TOKEN_SALE_ENABLED`
    })
      .then(response => {
        return response.data.payload.booleanValue
      })
  },
  createPersonalReferralLink: (context, anonymousUserId) => {
    return axios({
      method: 'POST',
      url: `${Store.state.rdbmsServiceAddress}/referrals`,
      data: { anonymousUserId, referralType: 'PERSONAL' }
    })
      .then(response => {
        return response.data.payload
      })
  },
  createCryptoenterUserReferral: (context, reff) => {
    return axios({
      method: 'POST',
      url: `${Store.state.rdbmsServiceAddress}/referrals`,
      data: { referralId: reff, referralType: 'CRYPTOENTER_USER' }
    })
      .then(response => {
        return response.data.payload
      })
  },
  getAnonymousUser: async (context) => {
    const fp = await FingerprintJS.load()
    const result = await fp.get()
    console.log(result.visitorId)

    return axios({
      method: 'GET',
      url: `${Store.state.rdbmsServiceAddress}/anonymous-users/${result.visitorId}`
    })
      .then(response => {
        if (response.data.status === 200) {
          return { user: response.data.payload, userId: result.visitorId }
        }
        return { userId: result.visitorId }
      })
  },
  createAnonymousUser: (context, { userId, reff }) => {
    return axios({
      method: 'POST',
      url: `${Store.state.rdbmsServiceAddress}/anonymous-users/`,
      data: { userId }
    })
      .then(response => {
        if (response.data.status === 200) {
          return response.data.payload
        }
      })
  },
  updateAnonymousUserVisitedDate: (context, { userId }) => {
    return axios({
      method: 'PUT',
      url: `${Store.state.rdbmsServiceAddress}/anonymous-users/`,
      data: { id: userId }
    })
      .then(response => {
        if (response.data.status === 200) {
          return response.data.payload
        }
      })
  },
  getCommunityLogo: (context, logoId) => {
    return axios({
      method: 'GET',
      url: `${Store.state.rdbmsServiceAddress}/attachments/${logoId}`
    })
      .then(response => {
        if (response.data.status === 200) {
          return response.data.payload
        }
        return {}
      })
  }
}

export default {
  actions
}
