import axios from 'axios'
import { FORM_TYPE_NAMES, FORM_TYPES, SELECTOR_VALUE_NAMES } from '@/constants/P2P'
import { CURRENCY_SHORT_CODES, FIAT_CURRENCY_NAMES } from '@/constants/auto-exchange'
import { getOfferPairName, makeP2PAssetDeals, makeP2PDeals } from '@/utils/p2p'
import ExtendedDealModel from '@/models/P2P/ExtendedDealModel'
import { AutoexchangeSignatures } from '@/modules/Signatures'
import DealModel from '@/models/P2P/DealModel'
import P2POfferModel from '@/models/P2P/P2POfferModel'
import { makeDealsV3 } from '@/utils/autoexchange'

const mockOffers = [
  // BUY
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '100',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '4',
    owned_value: {
      amount: '10',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '1',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '1000',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '100',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '5',
    owned_value: {
      amount: '15',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '2',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '1500',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '110',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '6',
    owned_value: {
      amount: '10',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '1',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '1100',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '90',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '7',
    owned_value: {
      amount: '20',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '1',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '1800',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
  // SELL
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '50',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '8',
    owned_value: {
      amount: '1100',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '1',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '22',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '100',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '9',
    owned_value: {
      amount: '1100',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '1',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '11',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '100',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '10',
    owned_value: {
      amount: '1500',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '1',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '15',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
]

const mockFiatSteps = {
  RUB: '5000',
  USD: '100',
  EUR: '100'
}

const mockFiatBanknotes = {
  RUB: [5, 10, 50, 100, 500, 1000, 5000],
  USD: [1, 2, 5, 10, 20, 50, 100],
  EUR: [5, 10, 20, 50, 100, 200]
}

const state = () => ({
  p2pOffers: [],
  p2pDealsMap: {},
  p2pUserOffers: [],
  p2pExtendedDealsMap: {},
  offersByType: {},
  fiatSteps: mockFiatSteps,
  fiatBanknotes: mockFiatBanknotes,
  p2pOperationType: SELECTOR_VALUE_NAMES.BUY,
  p2pOfferType: FORM_TYPES[1],
  ownedCurrency: FIAT_CURRENCY_NAMES.RUB,
  wantedCurrency: CURRENCY_SHORT_CODES.tether,
  p2pSettingsByCurrency: {},
  offerForEnterDeal: null,
  currentDeal: null,
  glassP2PObject: {},
  userOrders: [],
  assetOffers: [],
  p2pTypeDividers: []
})

const mutations = {
  setP2PTypeDividers (state, data) {
    state.p2pTypeDividers = data
  },
  setAssetOffers (state, data) {
    state.assetOffers = data
  },
  setP2POfferType (state, val) {
    state.p2pOfferType = val
  },
  setP2POffers (state, data) {
    state.p2pOffers = data
  },
  setP2PDealsMap (state, data) {
    state.p2pDealsMap = data
  },
  setP2PUsersOffers (state, data) {
    state.p2pUserOffers = data
  },
  setP2PExtendedDealsMap (state, data) {
    state.p2pExtendedDealsMap = data
  },
  setP2POffersByType (state, data) {
    state.offersByType = data
  },
  setP2POperationType (state, val) {
    state.p2pOperationType = val
  },
  setOwnedCurrency (state, val) {
    state.ownedCurrency = val
  },
  setWantedCurrency (state, val) {
    state.wantedCurrency = val
  },
  setP2PSettingsByCurrency (state, data) {
    state.p2pSettingsByCurrency = data
  },
  setFiatSteps (state, data) {
    state.fiatSteps = data
  },
  setFiatBanknotes (state, data) {
    state.fiatBanknotes = data
  },
  setOfferForEnterDeal (state, data) {
    state.offerForEnterDeal = data
  },
  setCurrentDeal (state, data) {
    state.currentDeal = data
  },
  updateCurrentDealStatus (state, newStatus) {
    state.currentDeal.status = newStatus
  },
  setP2PGlassObject (state, data) {
    state.glassP2PObject = data
  },
  setP2PUserOrders (state, data) {
    state.userOrders = data
  }
}

const actions = {
  async fetchP2PSettings ({ rootState, commit }) {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${rootState.backEndAddress}/admin/p2p-currency-params`
      })
      if (data?.status === 200) {
        const fiatSteps = {}
        const settings = {}
        const fiatBanknotes = {}
        data.payload.forEach(item => {
          const currency = CURRENCY_SHORT_CODES[item.currency]
          settings[currency] = item
          fiatSteps[currency] = item.step
          fiatBanknotes[currency] = item.banknotes
        })
        commit('setP2PSettingsByCurrency', settings)
        commit('setFiatSteps', fiatSteps)
        commit('setFiatBanknotes', fiatBanknotes)
      }
    } catch (err) {
      console.log({ err })
    }
  },
  async fetchP2POffers ({ rootState, commit }) {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${rootState.backEndAddress}/p2p/offers`,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      if (data.status !== 200) {
        throw Error('P2P Offers! Something went wrong')
      }
      const { payload: offers } = data
      // const { payload: offers } = {
      //   payload: [
      //     {
      //       'user_account_info': {
      //         'user_id': '243495509183',
      //         'wallet_id': 'W_699170b5-0749-4ddb-9e64-4cbd21a5f033',
      //         'account_id': 'A_699170b5-0749-4ddb-9e64-4cbd21a5f033'
      //       },
      //       'exchange_rate': '0',
      //       'owned_value': {
      //         'type': 'Asset',
      //         'amount': '0',
      //         'currency': null,
      //         'asset': {
      //           'name': 'Test1606_sell',
      //           'quantity': '20',
      //           'quantity_type': 'kg',
      //           'price': '10'
      //         },
      //         'fiat_types': [
      //           'CardAmount'
      //         ]
      //       },
      //       'wanted_value': {
      //         'type': 'Internal',
      //         'amount': '0',
      //         'currency': 'tether',
      //         'asset': {
      //           'name': '',
      //           'quantity': '0',
      //           'quantity_type': '',
      //           'price': '0'
      //         },
      //         'fiat_types': null
      //       },
      //       'response_offer_op_id': '',
      //       'requisites_hash': [],
      //       'city': '',
      //       'country': 'Iran',
      //       'min_match_amount': '5',
      //       'banknotes': [],
      //       'operation_id': '7953490b-5ac8-4fae-8e4c-9333b9851f7b',
      //       'lock_txs_op_id': '6b961c61-b52c-4b62-9b4b-affed184d71f',
      //       'number': 1,
      //       'timestamp': 1718537956,
      //       'status': 'Open'
      //     },
      //     {
      //       'user_account_info': {
      //         'user_id': '243495509183',
      //         'wallet_id': 'W_699170b5-0749-4ddb-9e64-4cbd21a5f033',
      //         'account_id': 'A_699170b5-0749-4ddb-9e64-4cbd21a5f033'
      //       },
      //       'exchange_rate': '0',
      //       'owned_value': {
      //         'type': 'Internal',
      //         'amount': '0',
      //         'currency': 'tether',
      //         'asset': {
      //           'name': '',
      //           'quantity': '0',
      //           'quantity_type': '',
      //           'price': '0'
      //         },
      //         'fiat_types': null
      //       },
      //       'wanted_value': {
      //         'type': 'Asset',
      //         'amount': '0',
      //         'currency': null,
      //         'asset': {
      //           'name': 'Test1606_sell',
      //           'quantity': '8',
      //           'quantity_type': 'kg',
      //           'price': '10'
      //         },
      //         'fiat_types': [
      //           'CardAmount'
      //         ]
      //       },
      //       'response_offer_op_id': '',
      //       'requisites_hash': [],
      //       'city': '',
      //       'country': 'Iran',
      //       'min_match_amount': '5',
      //       'banknotes': [],
      //       'operation_id': '7953490b-5ac8-4fae-8e4c-9333b9851f7b',
      //       'lock_txs_op_id': '6b961c61-b52c-4b62-9b4b-affed184d71f',
      //       'number': 1,
      //       'timestamp': 1718537957,
      //       'status': 'Open'
      //     },
      //     {
      //       'user_account_info': {
      //         'user_id': '243495509183',
      //         'wallet_id': 'W_699170b5-0749-4ddb-9e64-4cbd21a5f033',
      //         'account_id': 'A_699170b5-0749-4ddb-9e64-4cbd21a5f033'
      //       },
      //       'exchange_rate': '0',
      //       'owned_value': {
      //         'type': 'Asset',
      //         'amount': '0',
      //         'currency': null,
      //         'asset': {
      //           'name': 'Test1606',
      //           'quantity': '100',
      //           'quantity_type': 'pc',
      //           'price': '66'
      //         },
      //         'fiat_types': [
      //           'CardAmount'
      //         ]
      //       },
      //       'wanted_value': {
      //         'type': 'Internal',
      //         'amount': '0',
      //         'currency': 'tether',
      //         'asset': {
      //           'name': '',
      //           'quantity': '0',
      //           'quantity_type': '',
      //           'price': '0'
      //         },
      //         'fiat_types': null
      //       },
      //       'response_offer_op_id': '',
      //       'requisites_hash': [],
      //       'city': '',
      //       'country': 'Iran',
      //       'min_match_amount': '5',
      //       'banknotes': [],
      //       'operation_id': '7953490b-5ac8-4fae-8e4c-9333b9851f7b',
      //       'lock_txs_op_id': '6b961c61-b52c-4b62-9b4b-affed184d71f',
      //       'number': 1,
      //       'timestamp': 1718537958,
      //       'status': 'Open'
      //     }
      //   ]
      // }
      const { BUY, SELL } = SELECTOR_VALUE_NAMES
      const offersByType = { [BUY]: {}, [SELL]: {} }
      const assetOffers = []
      const glassP2PObject = {}
      offers.sort((a, b) => a.timestamp - b.timestamp)
      offers.forEach((offerItem) => {
        const offer = P2POfferModel(offerItem)
        if (offer.isAssetOffer) return assetOffers.push(offer)
        const { owned_value, exchange_rate } = offer
        const offerType = owned_value.type === FORM_TYPE_NAMES.INTERNAL
          ? SELL
          : BUY
        const pairName = getOfferPairName(offer)
        if (!offersByType[offerType][pairName]) offersByType[offerType][pairName] = {}
        if (!offersByType[offerType][pairName]?.[exchange_rate])
          offersByType[offerType][pairName][exchange_rate] = []
        offersByType[offerType][pairName][exchange_rate].push(offer)

        if (!glassP2PObject[pairName]) glassP2PObject[pairName] = []
        glassP2PObject[pairName].push(offer)
      })
      console.log('P2P OFFERS', { offers, offersByType })
      commit('setP2POffers', offers)
      commit('setP2POffersByType', offersByType)
      commit('setP2PGlassObject', glassP2PObject)
      commit('setAssetOffers', assetOffers)
    } catch (err) {
      console.log('fetchP2POffers', err)
    }
  },
  async fetchP2PDeals ({ rootState, commit, dispatch }) {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${rootState.backEndAddress}/p2p/user-matches`,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      if (data.status !== 200) {
        throw Error('P2P DEALS! Something went wrong')
      }
      const userDeals = data.payload
      // const userDeals = [
      //   {
      //     operation_id: '29d506d9-a33b-4d08-a3ad-f006a64eb7e9',
      //     offer_operation_id: '78d506d9-a33b-4d08-a3ad-f006a64ebrt',
      //     timestamp: 1714641589,
      //     user_account_info: {
      //       user_id: '243495509183'
      //     },
      //     status: 'WaitAccept',
      //     participant_user_id: '455781640546',
      //     matched_banknotes: ['50', '100'],
      //     matched_fiat_types: ['Cash', 'CardAmount'],
      //     requisites: [
      //       {
      //         type: 'Cash',
      //         address: 'Russia, Moscow',
      //         description: 'Some description'
      //       },
      //       {
      //         type: 'CardAmount',
      //         bank: 'Tinkoff',
      //         card_number: '420444411112222',
      //         description: 'Some description'
      //       }
      //     ],
      //     owned_amount: { amount: '1200', currency: 'RUB' },
      //     owned_reserve_amount: { amount: '12', currency: 'tether' },
      //     owned_documents: [],
      //     wanted_amount: { amount: '12', currency: 'tether' },
      //     wanted_reserve_amount: { amount: '12', currency: 'tether' },
      //     wanted_documents: [],
      //   }
      // ]
      // const userOffers = await dispatch('fetchP2PUserOffers')
      const dealsMap = {}
      // const extendedDealsMap = {}
      userDeals.forEach(i => {
        dealsMap[i.match.operation_id] = new DealModel(i)
      })
      // console.log({ dealsMap })
      // userOffers.forEach(i => {
      //   extendedDealsMap[i.operation_id] = new ExtendedDealModel({ ...i, deal: dealsMap[i.operation_id] })
      // })
      // commit('setP2PExtendedDealsMap', extendedDealsMap)
      console.log({ dealsMap })
      commit('setP2PDealsMap', dealsMap)
      // if (Object.values(dealsMap)[0]) commit('setCurrentDeal', Object.values(dealsMap)[0])
      // console.log('P2P DEALS', { extendedDealsMap, userOffers, dealsMap, userDeals })
      return true
    } catch (err) {
      console.log('fetchP2PDeals', err)
    }
  },
  async fetchP2PUserOffers ({ rootState, commit }) {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${rootState.backEndAddress}/p2p/user-offers`,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      if (data.status !== 200) {
        throw Error('P2P USER OFFERS! Something went wrong')
      }
      console.log('P2P USER OFFERS', { data })
      commit('setP2PUserOrders', data.payload)
      return data.payload
    } catch (err) {
      console.log('fetchP2PUserOffers', err)
    }
  },
  async fetchP2PTypeDividers ({ rootState, commit }) {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${rootState.backEndAddress}/admin/p2p-security`
      })
      if (data?.status === 200) {
        commit('setP2PTypeDividers', data.payload)
      }
    } catch (err) {
      console.log({ err })
    }
  },
  addP2PRequest ({ rootState }, data) {
    return axios({
      method: 'POST',
      url: `${rootState.backEndAddress}/p2p/add`,
      data,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  },
  acceptRejectAction ({ rootState }, { data, action }) {
    const actionUrlMap = {
      [AutoexchangeSignatures.action.acceptP2PMatch]: 'accept-match',
      [AutoexchangeSignatures.action.rejectP2PMatch]: 'reject-match',
    }
    return axios({
      method: 'POST',
      url: `${rootState.backEndAddress}/p2p/${actionUrlMap[action]}`,
      data,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  }
}

const getters = {
  getP2PTypeDividersByType: state => state.p2pTypeDividers.reduce((acc, item) => {
    acc[item.value_type] = item.percent
    return acc
  }, {}),
  getP2PAssetObj: state => {
    return state.assetOffers.reduce((acc, offer) => {
      if (!acc[offer.firstCurrencyShort]) acc[offer.firstCurrencyShort] = []
      acc[offer.firstCurrencyShort].push(offer)
      return acc
    }, {})
  },
  getP2PAssetMatchedOrders: (state, getters) => {
    return makeP2PAssetDeals(getters.getP2PAssetObj[state.wantedCurrency])
  },
  getP2PAssetOffersByType: (state, getters) => {
    const { BUY, SELL } = SELECTOR_VALUE_NAMES
    const offersByType = { [BUY]: [], [SELL]: [] }
    getters.getP2PAssetMatchedOrders.forEach(offer => {
      const { owned_value } = offer
      const offerType = owned_value.type === FORM_TYPE_NAMES.INTERNAL
        ? SELL
        : BUY
      if (!offersByType[offerType]) offersByType[offerType] = []
      offersByType[offerType].push(offer)
    })
    return offersByType
  },
  getP2PAssetOffers: (state, getters) => getters.getP2PAssetOffersByType[state.p2pOperationType],
  getP2PSettingsCurrencies: state => Object.keys(state.p2pSettingsByCurrency),
  p2pDeals: state => Object.values(state.p2pDealsMap),
  getExtendedOpenedP2PDeals: (state, getters) => getters.p2pDeals.map(i => state.p2pExtendedDealsMap[i.offer_operation_id]),
  glassP2PMatchedOrders: (state, getter, rootState, rootGetters) => {
    const pairName = `${state.wantedCurrency}/${state.ownedCurrency}`
    const orders = state.glassP2PObject?.[pairName] || []
    const decimalPlacesFa = Number(rootGetters.getProcessCurrencyObj('P2P_EXCHANGE', CURRENCY_SHORT_CODES[orders[0]?.first_currency])?.truncate_precision) || 2
    const decimalPlacesSa = Number(rootGetters.getProcessCurrencyObj('P2P_EXCHANGE', CURRENCY_SHORT_CODES[orders[0]?.second_currency])?.truncate_precision) || 2
    return makeP2PDeals(orders, decimalPlacesFa, decimalPlacesSa)
  },
  getP2POffersByType: (state, getters) => {
    const { BUY, SELL } = SELECTOR_VALUE_NAMES
    const offersByType = { [BUY]: {}, [SELL]: {} }
    getters.glassP2PMatchedOrders.forEach(offer => {
      const { owned_value, wanted_value, exchange_rate } = offer
      const offerType = owned_value.type === FORM_TYPE_NAMES.INTERNAL
        ? SELL
        : BUY
      const pairName = getOfferPairName(offer)
      if (!offersByType[offerType][pairName]) offersByType[offerType][pairName] = {}
      if (!offersByType[offerType][pairName]?.[exchange_rate])
        offersByType[offerType][pairName][exchange_rate] = []
      offersByType[offerType][pairName][exchange_rate].push(offer)
    })
    return offersByType
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
}
