import axios from 'axios'
import Store from '@/store'
import { Withdrawal } from '@/Methods/Withdrawal.js'

const actions = {
  getGas: (context, data) => {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/currencies/ethereum/gas-estimation`,
      params: data
    })
  },
  withdrawalAmount: (context, dataForSend) => {
    return Withdrawal(dataForSend)
      .then(sendData => {
        return axios({
          method: 'POST',
          url: `${Store.state.backEndAddress}/withdrawal`,
          data: sendData,
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': 'ru-RU'
          },
          // timeout: 1000
        })
      })
  }
}

export default {
  actions
}
