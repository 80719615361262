import axios from 'axios'
import Store from '@/store'

const actions = {
  refreshSession () {
    return axios({
      method: 'POST',
      url: `${Store.state.backEndAddress}/auth/refresh`
    })
  }
}

export default {
  actions
}
