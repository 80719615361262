import axios from 'axios'
import Store from '@/store'

const actions = {
  integrationsForms: (context, dataForSend) => {
    return axios({
      method: 'POST',
      url: `${Store.state.backEndAddressNotLink}/maildaemonnode/integrationsForm`,
      // url: `http://localhost:3220/maildaemonnode/integrationsForm`,
      data: dataForSend,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    })
      .then(response => {
        if (response.data.status === 200) {
          return true
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        throw new Error(err)
      })
  },
  emailSubscriptionConfirm: (context, { email, confirmation }) => {
    return axios({
      method: 'PUT',
      url: `${Store.state.rdbmsServiceAddress}/subscription`,
      // url: `http://localhost:3220/maildaemonnode/subscribe`,
      data: { email, confirmation },
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    })
      .then(response => {
        if (response.data.status === 200 && response.data.payload.confirmed !== null) {
          return true
        } else {
          return false
        }
      })
  },
  subscribe: (context, dataForSend) => {
    return axios({
      method: 'POST',
      url: `${Store.state.backEndAddress}/subscription?emailAddress=${dataForSend.email}`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
      .then(response => {
        if (response.data.status === 200) {
          return true
        } else {
          throw new Error(response.data.message)
        }
      })
      .catch(err => {
        throw new Error(err)
      })
  },
  joinForm: (context, dataForSend) => {
    return axios({
      method: 'POST',
      url: `http://localhost:3220/maildemon/joinForm`,
      data: dataForSend,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    })
      .then(response => {
        if (response.data.status === 200) {
          return true
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        throw new Error(err)
      })
  }
}

export default {
  actions
}
