import { currencies } from '@/models/Currencies'
import Decimal from 'decimal.js'
import { notify } from '@kyvg/vue3-notification'
import { getCurrencyCode } from '../models/Currencies'
import { SystemUsers } from '@/models/SystemUsers'

import router from '@/router'
import OfferModel from '@/models/requests/OfferModel'
import SocketOfferModel from '@/models/requests/SocketOfferModel'
import { getStateOffersName } from '@/Methods/OffersLists'
import { createDateOfTimeStamp } from '@/Methods/GlobalMethods'
import { CURRENCY_SHORT_CODES } from '@/constants/auto-exchange'

const { currentRoute } = router

/**
 * Функция для возврата строки из экспоненциального числа
 * @param digits - число в экспоненциальном виде
 * @param amountScale - максимальное значение ограничения числа после запятой
 * @returns {*|string} - число в "человеческом" виде в виде строки
 */
const exportExponentialToString = (digits, amountScale) => {
  let exportString = ''

  let str = String(digits)
  let order = str.replace(/.+e-/gm, '')
  // eslint-disable-next-line
  let mantis = str.match(/(\d*[\.\,]?\d+?)e/)
  let mantisLength = 0
  if (mantis[1].indexOf('.') !== -1) {
    let mantisArray = mantis[1].split('.')
    mantisLength = mantisArray[1].length
  }
  let totalExponentialDigits = Number(order) + Number(mantisLength)
  if (totalExponentialDigits < amountScale) {
    exportString = digits.toFixed(totalExponentialDigits)
  } else {
    exportString = digits.toFixed(amountScale)
  }
  return exportString
}

/**
 * Функция перевода строки с экспоненциальной записью в обычныю
 * @param amount
 * @returns {string}
 */
// eslint-disable-next-line no-unused-vars
const convertAmount = amount => {
  let str = String(Number(amount))
  if (str.indexOf('e') !== -1) {
    let numb = str.replace(/.+e-/gm, '')
    amount = exportExponentialToString(Number(amount), Number(numb))
  }
  return String(amount)
}

const updateCurrenciesList = (context, currency) => {
  if (currency?.includes('Cash')) return
  const currencyShortUpperCase = currency ? getCurrencyCode(currency)?.toUpperCase() : ''
  let currencies = context.getters.GetCurrencyList
  if (currencies.includes(currencyShortUpperCase)) return
  currencies.push(currencyShortUpperCase)
  context.commit('SetCurrencyList', currencies)
}

const actions = {
  NewTransferIncome (context, oneTransfer) {
    let paymentArray = []
    let currencyList = {}
    let preBalance = []
    let dateMass = []

    let userName = context.getters.GetUserName
    let transfer = ''
    if (typeof oneTransfer === 'string') {
      transfer = JSON.parse(oneTransfer)
    } else {
      transfer = oneTransfer
    }
    console.log('New transfer', transfer)
    let transactions = []
    if (typeof transfer['transaction_list'] !== 'undefined') {
      transactions = transfer.transaction_list?.transactions || []
    } else {
      transactions = transfer
    }
    var notified = false

    // старт рефакторинга это метода
    // кассиры
    const userTransactions = transactions.filter(({ user_account_info }) => {
      return context.getters.userWalletsIds.includes(user_account_info.wallet_id)
    })
    let isCash = false
    userTransactions.forEach(tr => {
      const {
        input_output_type,
        amount,
        user_account_info: { wallet_id: walletId, account_id: accountId }
      } = tr
      if (amount?.currency_type?.includes('Cash')) isCash = true
      const expressionMethod = input_output_type === 'INPUT' ? 'plus' : 'minus'
      const currency = CURRENCY_SHORT_CODES[amount.currency_type] || amount.currency_type
      const oldValue = context.rootState.Transactions.balanceObject?.[walletId]?.[accountId]?.[currency] || 0
      const newValue = new Decimal(oldValue)[expressionMethod](amount.amount).toFixed()
      context.commit('updateWalletObjectItem', { walletId, accountId, currency, newValue })
    })
    console.log({ userTransactions })
    // if (isCash) return

    for (let i = 0; i < transactions.length; i++) {
      let transaction = transactions[i]
      let payment = {}
      if (transactions[i].user_account_info.user_id !== userName) {
        // собираем данные для платежей
        for (let j = 0; j < transactions.length; j++) {
          if (transaction.dependency_key === transactions[i].key) {
            payment.myWallet = transactions[i].user_account_info.wallet_id
            payment.myAccount = transactions[i].user_account_info.account_id
            break
          }
        }
        if (transaction.input_output_type === 'OUTPUT') {
          payment.decAmount = new Decimal(transaction.amount.amount)
          payment.ammount = Number(transaction.amount.amount)
          payment.currency = transaction.amount.currency_type
          payment.name = currencies[transaction.amount.currency_type]
          payment.time = transaction.timestamp
          payment.timestamp = transaction.timestamp
          payment.typePayments = 'input'
          payment.userId = transaction.from_user_account_info.user_id
          payment.wallet = transaction.from_user_account_info.wallet_id
          if (!notified) {
            notified = true
            notify({
              title: 'Успех',
              text: 'New incoming transfer',
              type: 'success'
            })
          }
        } else if (transaction.input_output_type === 'INPUT') {
          payment.decAmount = new Decimal(transaction.amount.amount)
          payment.ammount = Number(transaction.amount.amount)
          payment.currency = transaction.amount.currency_type
          payment.name = currencies[transaction.amount.currency_type]
          payment.time = transaction.timestamp
          payment.timestamp = transaction.timestamp
          payment.typePayments = 'output'
          payment.userId = transaction.to_user_account_info.user_id
          payment.wallet = transaction.to_user_account_info.wallet_id
          payment.account = transaction.to_user_account_info.account_id
        }
        payment.operationId = transaction.operation_id
        payment.externalTxId = transaction.external_transaction_id
        payment.processType = transaction.process_type
        payment.operationType = transaction.operation_type
        payment.fromUserId = transaction.from_user_account_info.user_id
        payment.toUserId = transaction.to_user_account_info.user_id

        if (!currencyList[payment.currency]) {
          currencyList[payment.currency] = payment.name
        }
        paymentArray.push(payment)
      } else if (transactions[i].user_account_info.user_id === userName) {
        // Собираем данные для баланса баланс
        let tempObj = {}
        tempObj.wallet = transactions[i].user_account_info.wallet_id
        tempObj.account = transactions[i].user_account_info.account_id
        tempObj.decAmount = new Decimal(transactions[i].amount.amount)
        tempObj.amount = Number(transactions[i].amount.amount)
        tempObj.currency = transactions[i].amount.currency_type
        tempObj.name = currencies[transactions[i].amount.currency_type]
        tempObj.type = transactions[i].input_output_type
        preBalance.push(tempObj)
      }
      updateCurrenciesList(context, transaction.amount.currency_type)
    }

    for (let i = 0; i < paymentArray.length; i++) {
      // let date = getDate(paymentArray[i].time)
      let date = createDateOfTimeStamp(paymentArray[i].time)
      paymentArray[i].time = date
      if (i === 0) {
        dateMass[i] = {}
        dateMass[i].date = date
        dateMass[i].payments = []
      } else {
        let lengMass = dateMass.length
        let flag = false
        for (let j = 0; j < lengMass; j++) {
          if (dateMass[j].date === date) {
            flag = true
          }
        }
        if (!flag) {
          let obj = {}
          obj.date = date
          obj.payments = []
          dateMass.push(obj)
        }
      }
    }
    let countPayment = 0
    for (let i = 0; i < paymentArray.length; i++) {
      let payment = {}
      payment.decAmount = new Decimal(paymentArray[i].ammount)
      payment.ammount = paymentArray[i].ammount
      payment.currency = paymentArray[i].currency
      payment.name = currencies[paymentArray[i].currency]
      if (!paymentArray[i].userId) {
        payment.user = paymentArray[i].account
      } else {
        payment.user = paymentArray[i].userId
      }
      payment.type = paymentArray[i].typePayments
      payment.myWallet = paymentArray[i].myWallet
      payment.myAccount = paymentArray[i].myAccount
      payment.operationId = paymentArray[i].operationId
      payment.externalTxId = paymentArray[i].externalTxId
      payment.processType = paymentArray[i].processType
      payment.operationType = paymentArray[i].operationType
      payment.timestamp = paymentArray[i].timestamp
      payment.fromUserId = paymentArray[i].fromUserId
      payment.toUserId = paymentArray[i].toUserId
      if (paymentArray[i].wallet) {
        payment.wallet = paymentArray[i].wallet
      }
      for (let j = 0; j < dateMass.length; j++) {
        if (dateMass[j].date === paymentArray[i].time) {
          dateMass[j].payments.push(payment)
          if (payment.user !== SystemUsers.system_commission.code) {
            countPayment++
          }
        }
      }
    }
    context.commit('ChangeBalance', preBalance)
    context.commit('ChangePayments', dateMass)
    transactions
      .filter(i => {
        return context.getters.userWalletsIds.includes(i.user_account_info.wallet_id)
      })
      .sort((a, b) => a.timestamp - b.timestamp)
      .forEach(transaction => { context.commit('updateUserTransactions', transaction) })
    if (currentRoute.value?.name !== 'statement') context.commit('ChangeNewPaymentCount', countPayment)
  },
  NewRequest (context, request) {
    let newRequest = JSON.parse(request.body)
    // V1
    // if (newRequest.type === 'In') {
    //   let offer = newRequest.offer_records.offer_records
    //   let obj = {}
    //   for (let i = 0; i < offer.length; i++) {
    //     if (offer[i].input_output_type === 'INPUT') {
    //       obj.inCurrency = currencies[offer[i].amount.currency_type]
    //       obj.inAmount = offer[i].amount.amount
    //       obj.operationId = offer[i].operation_id
    //       obj.inCurrensyName = offer[i].amount.currency_type
    //     } else {
    //       obj.UserName = offer[i].user_account_info.user_id
    //       obj.outCurrency = currencies[offer[i].amount.currency_type]
    //       obj.outCurrencyName = offer[i].amount.currency_type
    //       obj.outAmount = offer[i].amount.amount
    //     }
    //   }
    //   obj.coursePair = obj.inCurrency + '/' + obj.outCurrency
    //   obj.course = Number(obj.inAmount) / Number(obj.outAmount)
    //   context.commit('NewOffer', obj)
    //   if (currentRoute.value?.name !== 'In request') context.commit('ChangeNewInRequest', 1)
    // } else if (newRequest.type === 'Out') {
    //   let data = JSON.parse(request.body)
    //   let requestData = data.offer_records.offer_records
    //   context.dispatch('NewTransferIncome', data)
    //   let obj = {}
    //   for (let i = 0; i < requestData.length; i++) {
    //     if (requestData[i].input_output_type === 'INPUT') {
    //       obj.inCurrency = currencies[requestData[i].amount.currency_type]
    //       obj.inAmount = requestData[i].amount.amount
    //       obj.operationId = requestData[i].operation_id
    //       obj.inCurrensyName = requestData[i].amount.currency_type
    //       obj.UserName = requestData[i].user_account_info.user_id
    //     } else {
    //       obj.outCurrency = currencies[requestData[i].amount.currency_type]
    //       obj.outCurrencyName = requestData[i].amount.currency_type
    //       obj.outAmount = requestData[i].amount.amount
    //     }
    //   }
    //   obj.coursePair = obj.outCurrency + '/' + obj.inCurrency
    //   obj.course = Number(obj.inAmount) / Number(obj.outAmount)
    //   context.commit('NewOutRequest', obj)
    //   if (currentRoute.value?.name !== 'Out request') context.commit('ChangeNewOutRequest', 1)
    // }
    // V2
    const socketOfferObj = new SocketOfferModel(newRequest)
    const objV2 = new OfferModel(socketOfferObj)
    context.commit('newRequestHandler', { offersName: getStateOffersName(socketOfferObj.request), data: objV2 })
    if (newRequest.type === 'In') {
      notify({
        title: 'Успех',
        text: 'New incoming request',
        type: 'success'
      })
      if (currentRoute.value?.name !== 'In request') context.commit('ChangeNewInRequest', 1)
    } else if (newRequest.type === 'Out') {
      context.dispatch('NewTransferIncome', newRequest)
      if (currentRoute.value?.name !== 'Out request') context.commit('ChangeNewOutRequest', 1)
    }
  },
  ChangeStatusRequest (context, data) {
    let request = JSON.parse(data.body)
    console.log('CHANGE STATUS', { request })
    // if (request.offer_status === 'OfferCanceled') {
    //   // V1
    //   // context.commit('CanceledRequest', request.offer_records[0].operation_id)
    //   // V2
    //   context.commit('canceledRequestHandler', request.offer_records[0])
    //   if (request.transactions) {
    //     context.dispatch('NewTransferIncome', request.transactions)
    //   }
    // }
    // V1
    // if (request.offer_status === 'OfferRejected') {
    //   context.commit('RejectedRequest', request.offer_records[0].operation_id)
    //   if (request.transactions) {
    //     context.dispatch('NewTransferIncome', request.transactions)
    //   }
    // }
    // if (request.offer_status === 'OfferAccepted') {
    //   context.commit('AcceptRequest', request.operation_id)
    //   context.dispatch('NewTransferIncome', request.transactions)
    // }
    // V2
    if (['OfferAccepted', 'OfferRejected', 'OfferCanceled'].includes(request.offer_status)) {
      const offer = request.offer_records[0]
      offer.offer_status = request.offer_status
      context.commit('acceptedRejectedRequestHandler', offer)
      if (request.transactions) {
        context.dispatch('NewTransferIncome', request.transactions)
      }
    }
  }
}

export default {
  actions
}
